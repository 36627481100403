import { Dialog as SharedDialog } from 'shared/components/Dialog'
import { Button } from './Button'

type DialogProps = {
  open: boolean
  onClose: () => void
  onValidate: () => void
  children: React.ReactNode
}
export const Dialog = ({
  open,
  onClose,
  onValidate,
  children,
}: DialogProps) => {
  if (!open) return null

  function handleValidate() {
    onValidate()
    onClose()
  }

  return (
    <SharedDialog onClose={onClose}>
      <div className="bg-background flex flex-col gap-4 rounded-md p-4">
        {children}
        <div className="flex flex-row justify-evenly gap-2">
          <Button primary onClick={handleValidate} type="submit">
            Valider
          </Button>
          <Button onClick={onClose} type="reset">
            Annuler
          </Button>
        </div>
      </div>
    </SharedDialog>
  )
}

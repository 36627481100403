import React, { useState } from 'react'
import { useDebounce } from '../hooks/useDebounce'
import { MetricsConfig, metricDisplayName } from '../utils/web/metrics'

type Props = {
  left: number
  right: number
  start: number
  end: number
  contrast: number
  text: string | undefined | null
  predictions: (number | null)[] | undefined
  metricsConfig: MetricsConfig
}

export function verticalBlockStyle(
  left: number,
  right: number,
  start: number,
  range: number,
) {
  return {
    left: `${((left - start) / range) * 100}%`,
    width: `${((right - left) / range) * 100}%`,
  }
}

const Predictions_: React.FC<Props> = ({
  left,
  right,
  start,
  end,
  contrast,
  text,
  predictions,
  metricsConfig,
}) => {
  const [hoveredPredictionIndex, setHoveredPredictionIndex] = useState<
    number | undefined
  >()
  const debouncedHoveredPredictionIndex = useDebounce(
    hoveredPredictionIndex,
    100,
  )

  const range = end - start

  return (
    <div
      className={`absolute inset-y-0 flex flex-col gap-1 ${
        predictions ? 'bg-blue-700 bg-opacity-10' : 'bg-red-400 bg-opacity-30'
      }`}
      style={verticalBlockStyle(left, right, start, range)}
    >
      <div className={`h-px ${text ? 'bg-white' : 'bg-transparent'}`} />
      {Object.entries(metricsConfig).map(([metricKey, metricConfig], index) => {
        const opacity = Math.min(
          1.0,
          ((predictions && predictions[index]) ?? 0) / (1.0 - contrast),
        )
        return (
          <div key={metricKey} className="relative flex flex-1">
            <div
              className="flex-1"
              style={{
                backgroundColor: metricConfig.color,
                opacity,
              }}
              onMouseEnter={() => setHoveredPredictionIndex(index)}
              onMouseLeave={() => setHoveredPredictionIndex(undefined)}
            ></div>
            {debouncedHoveredPredictionIndex === index && (
              <span
                className="absolute -top-4 left-full z-50 whitespace-nowrap rounded bg-gray-700 px-2 py-1 font-bold"
                style={{ color: metricConfig.color }}
              >
                {`${metricDisplayName(metricKey)} : ${
                  predictions?.[index]?.toFixed(2) ?? '???'
                }`}
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

export const Predictions = React.memo(Predictions_)

const MIN_DB = -57
const MAX_DB = -20

/**
 * A utility function for drawing our line segments
 * @param {CanvasRenderingContext2D} context the drawing context
 * @param {number} x  the x coordinate of the beginning of the line segment
 * @param {number} height the desired height of the line segment
 * @param {number} width the desired width of the line segment
 */
const drawLineSegment = (
  context: CanvasRenderingContext2D,
  x: number,
  height: number,
  width: number,
  canvasHeight: number,
) => {
  context.fillStyle = '#0D9488'
  context.fillRect(x, canvasHeight - height, width, height)
}

/**
 * Draws the audio file into a canvas element.
 * @param {Array} normalizedData The filtered array returned from filterData()
 * @returns {Array} a normalized array of data
 */
export const drawSoundsData = (
  canvas: HTMLCanvasElement,
  counts: number[],
  sums: number[],
) => {
  // set up the canvas
  const dpr = 1

  canvas.width = canvas.offsetWidth * dpr
  canvas.height = canvas.offsetHeight * dpr
  const ctx = canvas.getContext('2d')!
  ctx.scale(dpr, dpr)
  ctx.translate(0, 0) // set Y = 0 to be in the middle of the canvas

  // draw the line segments

  const width = canvas.offsetWidth / counts.length

  for (let i = 0; i < counts.length; i++) {
    if (counts[i]) {
      const count = counts[i]
      const sum = sums[i]
      const meanDecibels = 20 * (Math.log(sum / count) / Math.LN10)
      const clampedMeanDecibel = Math.min(
        1,
        Math.max(0, (meanDecibels - MIN_DB) / (MAX_DB - MIN_DB)),
      )
      const x = Math.round(width * i)
      const newX = Math.round(width * (i + 1))
      let height = clampedMeanDecibel * canvas.offsetHeight
      if (height < 0) {
        height = 0
      } else if (height > canvas.offsetHeight) {
        height = canvas.offsetHeight
      }
      drawLineSegment(ctx, x, height, newX - x, canvas.offsetHeight)
    }
  }
}

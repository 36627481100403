import { ListObjectsCommand, S3Client, _Object } from '@aws-sdk/client-s3'
import { DateTime } from 'luxon'
import { s3BucketName } from '../../types/aws'
import { noonDate } from '../time'

const listAllObjects = async (
  prefix: string,
  s3Client: S3Client,
  bucketName: string,
  nextMarker?: string,
): Promise<_Object[]> => {
  const input = {
    Bucket: bucketName,
    MaxKeys: 1000,
    Prefix: prefix,
    NextMarker: nextMarker,
  }
  const { Contents, IsTruncated, NextMarker } = await s3Client.send(
    new ListObjectsCommand(input),
  )
  return IsTruncated && NextMarker
    ? listAllObjects(prefix, s3Client, bucketName, NextMarker).then((x) =>
        Contents ? Contents.concat(x) : x,
      )
    : Contents || []
}

export type S3Sound = {
  endTimestamp: number
  soundKey: string
  textKey?: string
}

type FetchDataOptions = {
  bucketName?: string
}

export const fetchData = async (
  s3Client: S3Client,
  prefix: string,
  options?: FetchDataOptions,
) => {
  const bucketName = options?.bucketName ?? s3BucketName

  const matcher = new RegExp(`(^${prefix}.*)\\.(ogg|txt)$`)

  const s3Objects = await listAllObjects(prefix, s3Client, bucketName)

  const soundsData: Record<string, S3Sound> = {}

  for (const { Key } of s3Objects) {
    if (!Key) continue
    const matches = Key.match(matcher)
    if (!matches) continue
    const [, path, type] = matches

    const pathParts = path.split('/')
    const soundDate = pathParts[pathParts.length - 1]

    if (!soundsData[soundDate]) {
      soundsData[soundDate] = {
        // No timezone, read with offset, convert to underlying timestamp
        endTimestamp: new Date(soundDate).getTime(),
        soundKey: `error-${soundDate}`, // should be filled below
      }
    }

    switch (type) {
      case 'ogg':
        soundsData[soundDate].soundKey = Key
        break
      case 'txt':
        soundsData[soundDate].textKey = Key
        break
    }
  }

  return Object.values(soundsData).sort(
    ({ endTimestamp: a }, { endTimestamp: b }) => a - b,
  ) as S3Sound[]
}

export const formatPrefix = (serial: string, prefix: string) => {
  const prefixWithoutExtension = prefix.replace('.ogg', '')
  // Assumes a YYYY-MM-DDTHH:MM format for prefix, i.e. a valid dateTime
  const date = noonDate(DateTime.fromISO(prefixWithoutExtension))

  const fullPrefix = `${serial}/${date}/${prefixWithoutExtension}`

  return fullPrefix
}

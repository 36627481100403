import { DateTime } from 'luxon'
import { useMemo } from 'react'

export function formatTime(millis: number, withSeconds = false) {
  return DateTime.fromMillis(millis, { zone: 'Europe/Paris' }).toLocaleString(
    withSeconds ? DateTime.TIME_24_WITH_SECONDS : DateTime.TIME_24_SIMPLE,
  )
}

type Props = { start: number; end: number }

export const TimeMarks = ({ start, end }: Props) => {
  const timeMarks = useMemo(() => {
    const timeMarks = []
    const count = 12
    const duration = (end - start) / count
    for (let i = 0; i <= count; i++) {
      timeMarks.push(start + duration * i)
    }

    return timeMarks
  }, [start, end])

  return (
    <div className="flex flex-row justify-between">
      {timeMarks.map((time) => (
        <span key={time}>{formatTime(time)}</span>
      ))}
    </div>
  )
}

import { BucketPrefix, DevicesHistory } from 'common/types'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { DateString, Room, Serial } from 'shared/types/utils'
import { collator } from 'shared/utils/web/collator'
import { Button } from './components/Button'
import { Input } from './components/Input'
import { Select } from './components/Select'
import { useFirebase } from './hooks/useFirebase'

export function minDate() {
  // FIXME role not used correctly in old listen, acces to listen must be reviewed
  return '1900-01-01T00:00:00.000Z'
}

function maxDateTime() {
  const currentTime = DateTime.local().setZone('Europe/Paris')
  const shiftDate = currentTime.hour < 12
  return currentTime.minus({ day: shiftDate ? 1 : 0 })
}

export function maxDate() {
  return maxDateTime().toISODate()
}

export function calculateTimestampRange(
  selectedDate: string,
  extraRangeHours: number = 4,
) {
  const startDateTime = DateTime.fromISO(selectedDate)
    .setZone('Europe/Paris', { keepLocalTime: true })
    .plus({ hours: 12 - extraRangeHours })

  const start = startDateTime.toMillis()
  const end = startDateTime.plus({ hours: 24 + 2 * extraRangeHours }).toMillis()

  return {
    start,
    end,
  }
}

type SerialRoomAndFacility = Record<
  string,
  {
    room: Room
    facility: string
  }
>
type FacilitySerials = Record<string, Serial[]>

function defaultSerial(
  serialRoomAndFacility: SerialRoomAndFacility,
  facilitySerials: FacilitySerials,
) {
  const pathParts = window.location.pathname.split('/')
  const serial = pathParts[1]

  const serials = Object.keys(serialRoomAndFacility)
  if (serials.includes(serial)) return serial

  if (serial.length > 0) {
    window.alert(`Numéro de série inconnu : ${serial}`)
    const facility = 'Établissement inconnu'
    serialRoomAndFacility[serial] = { room: 'Pas de chambre', facility }
    facilitySerials[facility] = [serial]
    return serial
  }

  return serials[0]
}

function defaultDate() {
  const pathParts = window.location.pathname.split('/')
  const dateString = pathParts[2]
  if (pathParts.length > 2) {
    const date = DateTime.fromISO(dateString)
    if (date.isValid && dateString <= maxDate() && dateString >= minDate())
      return dateString
  }
  if (dateString) console.error(`Date invalide : ${dateString}`)
  return maxDate()
}

type Props = {
  onChange: (bucketPrefix: BucketPrefix) => void
  devicesHistory: DevicesHistory
}

function Path_({ onChange, devicesHistory }: Props) {
  const [selectedDate, setSelectedDate] = useState<string>(defaultDate())

  useEffect(() => {
    // Init from URL
    setSelectedDate(defaultDate())
  }, [])

  const { serialRoomAndFacility, facilitySerials } = useMemo(() => {
    const serialRoomAndFacility: SerialRoomAndFacility = {}
    const facilitySerials: FacilitySerials = {}

    // Some extra room around the noon-noon interval of the selected date
    const extraRangeHours = 4

    const { start: selectedStartTs, end: selectedEndTs } =
      calculateTimestampRange(selectedDate, extraRangeHours)

    for (const serial in devicesHistory) {
      const deviceHistory = Object.values(devicesHistory[serial])

      for (const { room, facility, startDate, endDate } of deviceHistory) {
        if (
          (!endDate || endDate >= selectedStartTs) &&
          startDate <= selectedEndTs
        ) {
          serialRoomAndFacility[serial] = { room, facility }
          if (!facilitySerials[facility]) facilitySerials[facility] = []
          if (!facilitySerials[facility].includes(serial))
            facilitySerials[facility].push(serial)
        }
      }
    }

    return { serialRoomAndFacility, facilitySerials }
  }, [selectedDate, devicesHistory])

  const [selectedSerial, setSelectedSerial] = useState<string>(
    defaultSerial(serialRoomAndFacility, facilitySerials),
  )

  // Correct selected serial upon date change if necessary
  useEffect(() => {
    if (!serialRoomAndFacility[selectedSerial]) {
      setSelectedSerial(Object.keys(serialRoomAndFacility)[0])
    }
  }, [serialRoomAndFacility, selectedSerial])

  type ValidatedState = {
    validatedDate: DateString
    validatedSerial: Serial
  }

  const [{ validatedDate, validatedSerial }, setValidatedState] =
    useState<ValidatedState>({
      validatedDate: selectedDate,
      validatedSerial: selectedSerial,
    })

  useEffect(() => {
    onChange(`${validatedSerial}/${validatedDate}`)
  }, [validatedSerial, validatedDate, onChange])

  const prevDate = () => {
    if (selectedDate > minDate())
      setSelectedDate(
        DateTime.fromISO(selectedDate).minus({ day: 1 }).toISODate(),
      )
  }

  const nextDate = () => {
    if (selectedDate < maxDate())
      setSelectedDate(
        DateTime.fromISO(selectedDate).plus({ day: 1 }).toISODate(),
      )
  }

  const validate = () => {
    setValidatedState({
      validatedDate: selectedDate,
      validatedSerial: selectedSerial,
    })
    window.history.replaceState(null, '', `/${selectedSerial}/${selectedDate}`)
  }

  const sortedFacilitySerials = useMemo(() => {
    return facilitySerials[serialRoomAndFacility[selectedSerial].facility].sort(
      (serialA, serialB) =>
        collator.compare(
          serialRoomAndFacility[serialA].room,
          serialRoomAndFacility[serialB].room,
        ),
    )
  }, [facilitySerials, serialRoomAndFacility, selectedSerial])

  const isValidatedState =
    selectedDate === validatedDate && selectedSerial === validatedSerial

  return (
    <div className="flex flex-row gap-4">
      <div className="flex gap-2">
        <Button onClick={prevDate} disabled={selectedDate <= minDate()}>
          &larr;
        </Button>
        <Input
          value={selectedDate}
          type="date"
          min={minDate()}
          max={maxDate()}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <Button onClick={nextDate} disabled={selectedDate >= maxDate()}>
          &rarr;
        </Button>
      </div>
      <Select
        onChange={(event) => {
          const selectedFacility = event.target.value
          const validSerials = facilitySerials[selectedFacility].filter(
            (serial) =>
              serialRoomAndFacility[serial].facility === selectedFacility,
          )
          if (validSerials.length === 0)
            alert(`No serial found for facility ${selectedFacility}`)
          setSelectedSerial(validSerials[0])
        }}
        value={serialRoomAndFacility[selectedSerial].facility}
      >
        {Object.keys(facilitySerials)
          .sort()
          .map((facility) => (
            <option key={facility} value={facility}>
              {facility}
            </option>
          ))}
      </Select>
      <Select
        onChange={(event) => setSelectedSerial(event.target.value)}
        value={selectedSerial}
      >
        {sortedFacilitySerials.map((serial) => {
          return (
            <option key={serial} value={serial}>
              {serialRoomAndFacility[serial].room} ({serial.slice(-4)})
            </option>
          )
        })}
      </Select>
      <Button disabled={isValidatedState} onClick={validate}>
        Valider
      </Button>
    </div>
  )
}

export function Path(props: Omit<Props, 'devicesHistory'>) {
  const {
    data: devicesHistory,
    loading,
    error,
  } = useFirebase('history/devices')

  return loading ? (
    <Centered>Chargement...</Centered>
  ) : error ? (
    <Centered>{error.message}</Centered>
  ) : devicesHistory === null ? (
    <Centered>Aucun historique</Centered>
  ) : (
    <Path_ devicesHistory={devicesHistory} {...props} />
  )
}

import { MetricKey } from '../../types/victoria'

export const metricPrefixes = [
  'aws_layer1_',
  'aws_dionysos_',
  'aws_alertor_',
  'aws_janus_',
]

export function metricDisplayName(metric: MetricKey) {
  if (metric in defaultMetrics) return defaultMetrics[metric].label

  return metricPrefixes.reduce((acc, prefix) => acc.replace(prefix, ''), metric)
}

export type MetricsConfig = Record<MetricKey, { label: string; color: string }>

// These names must match the metrics names defined in Victoria Metrics
export const defaultMetrics: MetricsConfig = {
  aws_layer1_Parole: {
    label: 'Parole',
    color: 'lime',
  },
  aws_layer1_Ronflement: {
    label: 'Ronflement',
    color: 'yellow',
  },
  aws_layer1_Toux: {
    label: 'Toux',
    color: 'red',
  },
  aws_layer1_AS: {
    label: 'AS',
    color: 'white',
  },
  'aws_layer1_Eau-toilettes': {
    label: 'Eau, toilettes',
    color: 'blue',
  },
  'aws_layer1_Bruit de pas': {
    label: 'Bruit de pas',
    color: 'fuchsia',
  },
  aws_layer1_Porte: {
    label: 'Porte',
    color: 'tomato',
  },
  aws_layer1_Brouhaha: {
    label: 'Brouhaha',
    color: 'gray',
  },
  aws_layer1_Respiration: {
    label: 'Respiration',
    color: 'olivedrab',
  },
  'aws_layer1_Lit draps': {
    label: 'Lit draps',
    color: 'turquoise',
  },
  'aws_layer1_Deux personnes ou plus': {
    label: '2 pers.',
    color: 'mediumspringgreen',
  },
  aws_layer1_Choc: {
    label: 'Choc',
    color: 'orange',
  },
  aws_layer1_Tambourinage: {
    label: 'Tambourinage',
    color: 'lightblue',
  },
  aws_layer1_Cri: {
    label: 'Cri',
    color: 'deeppink',
  },
  aws_layer1_Gemissement: {
    label: 'Gémissement',
    color: 'pink',
  },
  aws_layer1_Vomissement: {
    label: 'Vomissement',
    color: 'forestgreen',
  },
  'aws_layer1_Difficulte respiratoire': {
    label: 'Difficulté respiratoire',
    color: 'mediumslateblue',
  },
  aws_layer1_Television: {
    label: 'Télévision',
    color: 'LightGray',
  },
}

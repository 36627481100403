import { ErrorBoundary } from '@sentry/react'
import React from 'react'
import { Centered } from 'shared/components/Centered'
import { Login } from './Login'
import { Main } from './Main'
import { Button } from './components/Button'
import { useAuth } from './hooks/useAuth'
import { useFirebase } from './hooks/useFirebase'

export function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary
        fallback={
          <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="py-4">Une erreur est survenue</div>
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Rafraîchir
            </Button>
          </div>
        }
      >
        <Authentification />
        {import.meta.env.MODE !== 'production' && (
          <div className="pointer-events-none fixed bottom-2 right-2 text-lime-300">
            <b>STAGING</b>
          </div>
        )}
      </ErrorBoundary>
    </React.StrictMode>
  )
}

const Authentification: React.FC = () => {
  const { loading, error, data: user } = useAuth()

  return (
    <div className="flex min-h-screen flex-col">
      {loading ? (
        <Centered>Authentification...</Centered>
      ) : error !== null ? (
        <Centered>{error.message}</Centered>
      ) : user === null ? (
        <Login />
      ) : (
        <Authorization uid={user.uid} />
      )}
    </div>
  )
}

const Authorization: React.FC<{ uid: string }> = ({ uid }) => {
  const { data: user, loading, error } = useFirebase(`users/${uid}`)

  return loading ? (
    <Centered>Autorisation...</Centered>
  ) : error ? (
    <Centered>{error.message}</Centered>
  ) : !user ? (
    <Centered>Utilisateur inconnu</Centered>
  ) : (
    <Main />
  )
}

import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import './index.css'

Sentry.init({
  enabled: !!import.meta.env.VITE_VERSION,
  dsn: 'https://f14fdef26f2a6ef9adffab4fa19e477d@o566331.ingest.sentry.io/4506200877629440',
  integrations: [Sentry.browserTracingIntegration()],
  environment: import.meta.env.MODE,
  tracesSampleRate: 0.1,
  release: `${import.meta.env.MODE}-${import.meta.env.VITE_VERSION}`,
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<App />)

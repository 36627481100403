/**
 * Filters the AudioBuffer retrieved from an external source
 * @param {AudioBuffer} audioBuffer the AudioBuffer from drawAudio()
 * @returns {Array} an array of floating point numbers
 */
export const aggregateSoundsData = (
  counts: number[],
  sums: number[],
  min: number,
  max: number,
  endTimestamp: number,
  audioBuffer: AudioBuffer,
  canvasWidth: number,
) => {
  const rawData = audioBuffer.getChannelData(0) // We only need to work with one channel of data
  const range = max - min
  const duration = audioBuffer.duration * 1000

  for (let i = 0; i < rawData.length; i++) {
    const percent = i / rawData.length
    const ellapsed = endTimestamp - duration + percent * duration
    const x = Math.floor(((ellapsed - min) / range) * canvasWidth)
    counts[x] += 1
    sums[x] += Math.abs(rawData[i])
  }
}

import { DeviceState } from 'shared/types/listen'
import { z } from 'zod'

export type DeviceHistory = DeviceState[]

export type DevicesHistory = Record<string, DeviceHistory>

export const MILVUS_MODES = ['all', 'serialOnly', 'facilityOnly'] as const

export type MilvusMode = (typeof MILVUS_MODES)[number]

export const FetchSimilarParticlesSchema = z.object({
  particleRef: z.string(),
  mode: z.enum(MILVUS_MODES),
})

export type FetchSimilarParticlesParams = z.infer<
  typeof FetchSimilarParticlesSchema
>

export type FetchSimilarParticlesResponseData = string[]

export type BucketPrefix = string

export const BUCKET_NAMES = ['oso-sounds', 'oso-resp-sounds'] as const

export type BucketName = (typeof BUCKET_NAMES)[number]

import { SelectHTMLAttributes } from 'react'

type SelectProps = SelectHTMLAttributes<HTMLSelectElement>

export const Select: React.FC<SelectProps> = ({
  children,
  className,
  ...props
}) => (
  <select
    className={`cursor-pointer rounded bg-sky-700 px-2 py-1 focus:outline-none disabled:opacity-50 ${
      className ?? ''
    }`}
    {...props}
  >
    {children}
  </select>
)

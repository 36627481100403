import React from 'react'
import { MetricsConfig } from 'shared/utils/web/metrics'
import {
  BUCKET_DURATION,
  Prefix,
  PrefixDataMap,
  SOUND_DURATION,
} from './Explorer'
import { PredictionsPreview } from './PredictionsPreview'
import { Predictions } from './hooks/usePredictions'

type Props = {
  metricsConfig: MetricsConfig
  bucketPrefixes: Prefix[]
  prefixDataMap: PrefixDataMap
  predictions: Record<Prefix, Predictions>
  selectedPrefix: Prefix | null
  onBucketClick: (prefix: Prefix) => void
}

const Buckets_ = ({
  metricsConfig,
  bucketPrefixes,
  prefixDataMap,
  predictions,
  selectedPrefix,
  onBucketClick,
}: Props) => {
  return (
    <>
      <div className="flex h-16 flex-row">
        {bucketPrefixes.map((prefix) => {
          return (
            <div
              key={prefix}
              className={`relative flex flex-1 bg-white ${
                prefixDataMap[prefix] === null
                  ? 'bg-opacity-5'
                  : 'bg-opacity-20'
              } outline outline-1 ${
                selectedPrefix === prefix
                  ? 'z-10 outline-white'
                  : 'outline-gray-700'
              }`}
              onClick={() => onBucketClick(prefix)}
            >
              {prefixDataMap[prefix] && (
                <div
                  className="absolute bottom-0 left-0 right-0 bg-teal-600"
                  style={{
                    height:
                      ((prefixDataMap[prefix]?.data || []).length /
                        (BUCKET_DURATION / SOUND_DURATION)) *
                        100 +
                      '%',
                  }}
                ></div>
              )}
            </div>
          )
        })}
      </div>
      <div
        className="flex flex-row"
        style={{ height: `${5.5 * Object.keys(metricsConfig).length}px` }}
      >
        {bucketPrefixes.map((prefix) => {
          return (
            <div className="flex flex-1" key={prefix}>
              {predictions[prefix] !== undefined &&
              Object.keys(predictions[prefix]).length > 0 ? (
                <PredictionsPreview
                  metricsConfig={metricsConfig}
                  predictions={predictions[prefix]}
                />
              ) : null}
            </div>
          )
        })}
      </div>
    </>
  )
}

export const Buckets = React.memo(Buckets_)

import { BUCKETS_CONFIG_MAP } from 'common/bucketConfig'
import { BUCKET_NAMES, BucketName } from 'common/types'
import { useState } from 'react'
import { Dialog } from './components/Dialog'

type BucketDialogProps = {
  bucket: BucketName
  handleValidate: (bucket: BucketName) => void
}
export function BucketDialog({ bucket, handleValidate }: BucketDialogProps) {
  const [value, setValue] = useState<BucketName>(bucket)
  const [showBucketModal, setShowBucketModal] = useState(false)

  return (
    <>
      <div
        className="bg-background-500 fixed bottom-2 left-2 z-10 cursor-pointer rounded-full px-2 text-lime-300"
        onClick={() => setShowBucketModal(true)}
      >
        <b>{BUCKETS_CONFIG_MAP[bucket].displayName}</b>
      </div>
      <Dialog
        open={showBucketModal}
        onClose={() => setShowBucketModal(false)}
        onValidate={() => handleValidate(value)}
      >
        <div>
          {BUCKET_NAMES.map((bucketName) => (
            <div key={bucketName}>
              <input
                type="radio"
                id={bucketName}
                name="bucket"
                value={bucketName}
                checked={value === bucketName}
                onChange={() => setValue(bucketName)}
              />
              <label htmlFor={bucketName} className="ml-2">
                {BUCKETS_CONFIG_MAP[bucketName].displayName}
              </label>
            </div>
          ))}
        </div>
      </Dialog>
    </>
  )
}
